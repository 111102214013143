import request from "@/api/service";

const api = {
   config: '/hotel/getCfgContent',
   save: '/hotel/saveCfg'
};

export function saveCfgContent(params) {
   return request({
      url: api.save,
      method: 'POST',
      data: params
   })
}

export function getCfgContent(params) {
   return request({
      url: api.config,
      method: 'GET',
      params
   })
}

