<template>
   <div class="cont">
      <Breadcrumb :crumbs="crumbs"/>
      <el-row class="cont-form-box">
        <el-form ref="empowerForm" :model="empowerForm" required>
          <el-form-item label="企业ID" prop="corpId" required>
            <el-input class="width-434" v-model="empowerForm.corpId"></el-input>
          </el-form-item>
          <el-form-item label="服务商Secret" prop="corpSecret" required>
            <el-input class="width-434" v-model="empowerForm.corpSecret"></el-input>
          </el-form-item>
          <el-form-item label="应用回调token" prop="token" required>
            <el-input class="width-434" v-model="empowerForm.token"></el-input>
          </el-form-item>
          <el-form-item label="应用回调加密token" prop="encodingAESKey" required>
            <el-input class="width-434" v-model="empowerForm.encodingAESKey"></el-input>
          </el-form-item>
          <el-form-item label="通讯录回调Token" prop="bookToken" required>
            <el-input class="width-434" v-model="empowerForm.bookToken"></el-input>
          </el-form-item>
          <el-form-item label="通讯录回调EncodingAESKey" prop="bookEncodingAESKey" required>
            <el-input class="width-434" v-model="empowerForm.bookEncodingAESKey"></el-input>
          </el-form-item>
          <el-form-item class="footer-btn">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="saveConfig">保存</el-button>
          </el-form-item>
        </el-form>
      </el-row>
   </div>
</template>
<script>
import { getCfgContent, saveCfgContent } from '@/api/data/wechat/config';
import { mapState } from 'vuex'
export default {
  data () {
    return {
      crumbs: new Map([
        ['数据中台'], ['企业微信'], ['微信配置']
      ]),
      empowerForm: {
        corpId: '',
        corpSecret: '',
        token: '',
        encodingAESKey: '',
        bookToken: '',
        bookEncodingAESKey: ''
      },
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.getConfig()
  },
  methods: {
    // 保存企业微信配置
    saveConfig() {
      this.$refs.empowerForm.validate(valid => {
        if (!valid) return
        const params = {
          hotelId: this.hotelInfo.id, content: JSON.stringify(this.empowerForm),
          hotelConfig: 'WECHAT_ENTERPRISE', companyCfg: 'true', appId: 'ww71a4d48033828078'
        };
        saveCfgContent(params).then(({ success }) => {
          if (!success) return;
          this.$message({ message: '保存成功！', type: 'success' })
        })
      })
    },
    // 重置配置信息
    handleReset() {
      for (const k in this.empowerForm) this.empowerForm[k] = ''
    },
    // 获取配置信息
    getConfig() {
      const params = { hotelId: this.hotelInfo.id, cfgCode: 'wechat-enterprise' }
      getCfgContent(params).then(({ success, records }) => {
        if (!success || !records) return;
        this.empowerForm = JSON.parse(records)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cont-form-box{
  .el-form-item{ margin-bottom: 20px }
  ::v-deep .el-form-item__error{ margin-left: 210px }
  ::v-deep .el-form-item__label{ width: 210px }
  .footer-btn{ margin: 30px 0 0 210px }
}
</style>
